/**
 * @name Organizacion.ts
 * @description Estructra organizativa de COFEPRIS
 * @author @tirsomartinezreyes
 * @version 1.0.2
 * @changelog 1.0.1 - 22/sep/24 - @tirsomartinezreyes - Se agregan unidades organizativas de insumos para la salud, se refactoriza para utilizar enumeraciones y se agregan actores a las unidades organizativas de insumos para la salud
 */
import { RolType } from './Roles'
import { CatalogGenericData } from './Catalogs'
import { IConfigValues, IGlobalConfig } from 'cofepris-typesafe/Types/ConfigsSystem'

export enum ECargoUnidadOrganizativa {
	DIRECTOR_EJECUTIVO_DE_AUTORIZACION_DE_PRODUCTOS_Y_ESTABLECIMIENTOS = 'DIRECTOR_EJECUTIVO_DE_AUTORIZACION_DE_PRODUCTOS_Y_ESTABLECIMIENTOS',
	SUBDIRECTOR_EJECUTIVO_DE_FARMACOS_Y_MEDICAMENTOS = 'SUBDIRECTOR_EJECUTIVO_DE_FARMACOS_Y_MEDICAMENTOS',
	DIRECTORA_EJECUTIVA_DE_AUTORIZACION_DE_PRODUCTOS_Y_ESTABLECIMIENTOS = 'DIRECTORA_EJECUTIVA_DE_AUTORIZACION_DE_PRODUCTOS_Y_ESTABLECIMIENTOS',
	SUBDIRECTORA_EJECUTIVA_DE_FARMACOS_Y_MEDICAMENTOS = 'SUBDIRECTORA_EJECUTIVA_DE_FARMACOS_Y_MEDICAMENTOS',
	GERENTE_DE_MEDICAMENTOS_ALOPATICOS = 'GERENTE_DE_MEDICAMENTOS_ALOPATICOS',
	GERENTE_DE_HERBOLARIOS_HOMEOPATICOS_Y_MEDICAMENTOS_ALTERNATIVOS = 'GERENTE_DE_HERBOLARIOS_HOMEOPATICOS_Y_MEDICAMENTOS_ALTERNATIVOS',
	SIN_CARGO = 'SIN_CARGO'
}

export const DictonaryCargosUnidadOrganizativa: { [key in ECargoUnidadOrganizativa]: string } = Object.freeze({
	[ECargoUnidadOrganizativa.DIRECTOR_EJECUTIVO_DE_AUTORIZACION_DE_PRODUCTOS_Y_ESTABLECIMIENTOS]: 'Director Ejecutivo de Autorización de Productos y Establecimientos',
	[ECargoUnidadOrganizativa.SUBDIRECTOR_EJECUTIVO_DE_FARMACOS_Y_MEDICAMENTOS]: 'Subdirector Ejecutivo de Farmacos y Medicamentos',
	[ECargoUnidadOrganizativa.DIRECTORA_EJECUTIVA_DE_AUTORIZACION_DE_PRODUCTOS_Y_ESTABLECIMIENTOS]: 'Directora Ejecutiva de Autorización de Productos y Establecimientos',
	[ECargoUnidadOrganizativa.SUBDIRECTORA_EJECUTIVA_DE_FARMACOS_Y_MEDICAMENTOS]: 'Subdirectora Ejecutiva de Farmacos y Medicamentos',
	[ECargoUnidadOrganizativa.GERENTE_DE_MEDICAMENTOS_ALOPATICOS]: 'Gerente de Medicamentos Alopáticos',
	[ECargoUnidadOrganizativa.GERENTE_DE_HERBOLARIOS_HOMEOPATICOS_Y_MEDICAMENTOS_ALTERNATIVOS]: 'Gerente de Herbarios, Homeopáticos y Medicamentos Alternativos',
	[ECargoUnidadOrganizativa.SIN_CARGO]: 'Sin cargo'
})

export interface Usuario {
	uid: string //CURP DEL USUARIO extraído del certificado X.509 de e.firma
	uuid: string //UUID DEL USUARIO extraído del certificado X.509 de e.firma
	rfc: string //RFC DEL USUARIO extraído del certificado X.509 de e.firma
	nombre: string //NOMBRE DEL USUARIO extraído del certificado X.509 de e.firma
	email: string //EMAIL DEL USUARIO extraído del certificado X.509 de e.firma
	accesoPermitido: boolean //ESTATUS DEL USUARIO para obtener tokens de Identidad y de Acceso
	certificadoActivo: string //Número de serie del CERTIFICADO X.509 DE e.firma activo
	requiereAutenticador: boolean //Indica si el usuario requiere un autenticador para acceder al sistema
	autenticadorRegistrado: boolean //Indica si el usuario ya ha registrado un autenticador
	grupos?: string //CSV de los grupos a los que pertenece el usuario  // No requerido para los nodos del arbol de recursos en el grupo de los solicitantes
	unidadesOrganizativas?: string //CSV de las unidades organizativas a las que pertenece el usuario cuando es un usuario interno de la organización // No requerido para los nodos del arbol de recursos en el grupo de los solicitantes
	permisos?: Permiso[] //Arreglo de permisos que el usuario tiene asignados // No requerido para los nodos del arbol de recursos en el grupo de los solicitantes
	perteneceOrganizacion?: boolean //Indica si el usuario pertenece a la organización // No requerido para los nodos del arbol de recursos en el grupo de los solicitantes
	cargo?: ECargoUnidadOrganizativa //Solo válido para la acción ACTUALIZAR_USUARIO
}
export interface IUsuarioUnidadOrganizativa {
	id: string //id de la Unidad Organizativa
	uid: string //CURP DEL USUARIO extraído del certificado X.509 de e.firma
	uuid: string //UUID DEL USUARIO generado
	nombre: string //NOMBRE DEL USUARIO extraído del certificado X.509 de e.firma
}

export interface IUsuarioUUID {
	uuid: string //UUID DEL USUARIO
	nombre?: string //NOMBRE DEL USUARIO
	name?: string //NOMBRE DEL USUARIO (compatibilidad con el servicio)
}

export interface OrganizacionUsuarioAPIRequest {
	//Solo válido para el servicio de ORGANIZATION
	accion?:
		| 'LISTAR_USUARIOS'
		| 'BUSCAR_USUARIO'
		| 'OBTENER_USUARIO'
		| 'ACTUALIZAR_USUARIO'
		| 'AGREGAR_USUARIO_ORGANIZACION'
		| 'RETIRAR_USUARIO_ORGANIZACION'
		| 'PERMITIR_ACCESO'
		| 'DENEGAR_ACCESO'
		| 'REMOVER_AUTENTICADOR'
		| 'ACTUALIZAR_UNIDADES_ORGANIZATIVAS'
		| 'CREAR_PERMISOS'
		| 'REVOCAR_PERMISO'
		| 'REQUERIR_AUTENTICADOR'
		| 'NO_REQUERIR_AUTENTICADOR'
		| 'AGREGAR_USUARIO_SOLICITANTE'
		| 'LISTAR_USUARIOS_SOLICITANTE'

	uuid?: string //Requerido para acciones OBTENER_USUARIO, PERMITIR_ACCESO, DENEGAR_ACCESO,REMOVER_AUTENTICADOR ,ACTUALIZAR_UNIDADES_ORGANIZATIVAS, REVOCAR_PERMISO, REQURIR_AUTENTICADOR, NO_REQUERIR_AUTENTICADOR
	unidadesOrganizativas?: string //Solo válido para la acción de actualizar unidades ACTUALIZAR_UNIDADES_ORGANIZATIVAS
	certificadoB64?: string //Solo válido para la acción de CREAR_USUARIO y AGREGAR_USUARIO_SOLICITANTE
	permisoRequest?: OrganizacionPermisoRequest //Solo válido para la acción de CREAR_PERMISOS
	uuids?: string[] //Solo válido para la acción de GET_USERS_BY_UUIDS

	//Solo válido para el servicio de USERS_BY_ORGANIZATIVE_UNIT
	unidadOrganizativa?: string
	rol?: RolType //Solo válido para la acción de USERS_BY_ORGANIZATIVE_UNIT
	lastEvaluatedKey?: Record<string, unknown> //Solo válido para la acción de LISTAR_USUARIOS
	id?: string //Requerido para la acción BUSCAR_USUARIO
	limit?: number //Solo válido para la acción LISTAR_USUARIOS
	cargo?: ECargoUnidadOrganizativa //Solo válido para la acción ACTUALIZAR_USUARIO
}

export interface OrganizacionUsuarioAPIResponse {
	status?: 'OK' | 'ERROR' | 'NETWORK_ERROR' //esta valor es poblado por el manejador de llamadas a api de Frontend a la respuesta de API o error de Browser OK=200,ERROR=400, NETWORK_ERROR cualquier otro código HTTP. No se espera en el cuerpo de la respuesta del API
	message: string
	data: {
		usuario?: Usuario //válido para las acciónes de OBTENER_USUARIO y como respuesta de CREAR_USUARIO,PERMITIR_ACCESO, DENEGRAR_ACCESO, REMOVER_AUTENTICADOR, ACTUALIZAR_UNIDADES_ORGANIZATIVAS, CREAR_PERMISOS, REVOCAR_PERMISO, REQURIR_AUTENTICADOR, NO_REQUERIR_AUTENTICADOR
		usuarios?: Usuario[] //Solo válido para la acción de LISTAR_USUARIOS
		users?: IUsuarioUnidadOrganizativa[] | IUsuarioUUID[] //IUsuarioUnidadOrganizativa válido para el servicio USERS_BY_ORGANIZATIVE_UNIT, IUsuarioUUID válido para el servicio GET_USERS_BY_UUIDS
		lastEvaluatedKey?: Record<string, unknown> //Solo válido para la acción de LISTAR_USUARIOS
	}
}

export interface OrganizacionConfigAPIResponse {
	status?: 'OK' | 'ERROR' | 'NETWORK_ERROR' //esta valor es poblado por el manejador de llamadas a api de Frontend a la respuesta de API o error de Browser OK=200,ERROR=400, NETWORK_ERROR cualquier otro código HTTP. No se espera en el cuerpo de la respuesta del API
	message: string
	data: IConfigValues | IGlobalConfig
}

export type Operaciones = 'READ' | 'UPDATE' | 'DELETE' | 'CREATE'

export interface OrganizacionPermisoRequest {
	uuid?: string // Requerido para CREAR_PERMISOS y REVOCAR_PERMISO
	rol?: RolType // Solo válido para la acción CREAR_PERMISOS y AGREGAR_USUARIO_SOLICITANTE
	dias?: number // Solo válido para la acción CREAR_PERMISOS y AGREGAR_USUARIO_SOLICITANTE
	idPermiso?: string // Solo válido para la acción REVOCAR_PERMISO
	recursos?: { homoclave: string; modalidad: string }[] // Solo válido para la acción CREAR_PERMISOS
}

export interface Permiso {
	idPermiso: string
	uid?: string //No disponible en casos en los que se comprometa la identidad de los evaluadores, coordinadores o administradores hacia los solicitantes
	uuid: string
	grupo: string
	rol: RolType
	recurso: string
	inicio: number
	fin: number
	otorganteUid?: string //No disponible en casos en los que se comprometa la identidad de los evaluadores, coordinadores o administradores hacia los solicitantes
	otorganteUuid: string | null
	otorganteNombre?: string | null
	otorganteRol: RolType | null
	revocado: boolean | null
	revocanteUid?: string //No disponible en casos en los que se comprometa la identidad de los evaluadores, coordinadores o administradores hacia los solicitantes
	revocanteUuid: string | null
	revocanteRol: RolType | null
	fechaRevocacion: number | null
}

export interface IActor {
	idActor: string
	actor: string
}

export enum EActor {
	DICTAMINADOR_RESPONSABLE = 'DICTAMINADOR_RESPONSABLE',
	DICTAMINADOR_ESTADISTICO = 'DICTAMINADOR_ESTADISTICO',
	AUXILIAR_SOLO_LECTURA = 'AUXILIAR_SOLO_LECTURA',
	COORDINADOR_COMITE_MOLECULAS_NUEVAS = 'COORDINADOR_COMITE_MOLECULAS_NUEVAS',
	DICTAMINADOR_QUIMICO_RESPONSABLE = 'DICTAMINADOR_QUIMICO_RESPONSABLE',
	DICTAMINADOR_MEDICO_RESPONSABLE = 'DICTAMINADOR_MEDICO_RESPONSABLE'
}

export const Actores: Record<EActor, IActor> = {
	[EActor.DICTAMINADOR_RESPONSABLE]: {
		idActor: 'DICTAMINADOR_RESPONSABLE',
		actor: 'Dictaminador Responsable'
	},
	[EActor.DICTAMINADOR_ESTADISTICO]: {
		idActor: 'DICTAMINADOR_ESTADISTICO',
		actor: 'Dictaminador Estadístico'
	},
	[EActor.AUXILIAR_SOLO_LECTURA]: {
		idActor: 'AUXILIAR_SOLO_LECTURA',
		actor: 'Auxiliar Solo Lectura'
	},
	[EActor.COORDINADOR_COMITE_MOLECULAS_NUEVAS]: {
		idActor: 'COORDINADOR_COMITE_MOLECULAS_NUEVAS',
		actor: 'Coordinador de Comité de Moléculas Nuevas'
	},
	[EActor.DICTAMINADOR_QUIMICO_RESPONSABLE]: {
		idActor: 'DICTAMINADOR_QUIMICO_RESPONSABLE',
		actor: 'Dictaminador Químico Responsable'
	},
	[EActor.DICTAMINADOR_MEDICO_RESPONSABLE]: {
		idActor: 'DICTAMINADOR_MEDICO_RESPONSABLE',
		actor: 'Dictaminador Médico Responsable'
	}
}

export interface UnidadOrganizativa {
	id: EOrganizacionUnidadesOrganizativas
	unidadOrganizativa: string
	seleccionable?: boolean
	actores?: EActor[]
	hijos?: UnidadOrganizativa[]
}

export enum EOrganizacionUnidadesOrganizativas {
	RAIZ = 'RAIZ',
	COFEPRIS = 'COFEPRIS',
	COFEPRIS_CSFS = 'COFEPRIS_CSFS',
	COFEPRIS_CSFS_DESP = 'COFEPRIS_CSFS_DESP',
	COFEPRIS_CAS = 'COFEPRIS_CAS',
	COFEPRIS_CAS_DEAPE = 'COFEPRIS_CAS_DEAPE',
	COFEPRIS_CAS_DEAPE_SEFM = 'COFEPRIS_CAS_DEAPE_SEFM',
	COFEPRIS_CAS_DEAPE_SEFM_EC = 'COFEPRIS_CAS_DEAPE_SEFM_EC',
	COFEPRIS_CAS_DEAPE_SEFM_GMA = 'COFEPRIS_CAS_DEAPE_SEFM_GMA',
	COFEPRIS_CAS_DEAPE_SEFM_GMA_BBV = 'COFEPRIS_CAS_DEAPE_SEFM_GMA_BBV',
	COFEPRIS_CAS_DEAPE_SEFM_GMA_NR = 'COFEPRIS_CAS_DEAPE_SEFM_GMA_NR',
	COFEPRIS_CAS_DEAPE_SEFM_GMA_MCR = 'COFEPRIS_CAS_DEAPE_SEFM_GMA_MCR',
	COFEPRIS_CAS_DEAPE_SEFM_GMA_FAEE = 'COFEPRIS_CAS_DEAPE_SEFM_GMA_FAEE',
	COFEPRIS_CAS_DEAPE_SEFM_GMA_HUERFANOS = 'COFEPRIS_CAS_DEAPE_SEFM_GMA_HUERFANOS',
	COFEPRIS_CAS_DEAPE_SEFM_GMHHA = 'COFEPRIS_CAS_DEAPE_SEFM_GMHHA',
	COFEPRIS_CAS_DEAPE_SESSDM = 'COFEPRIS_CAS_DEAPE_SESSDM',
	COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH = 'COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH',
	COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_NRCI = 'COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_NRCI',
	COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_NRCII = 'COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_NRCII',
	COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_NRCIII = 'COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_NRCIII',
	COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_DCR = 'COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_DCR',
	COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_DP = 'COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_DP',
	COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_NRE = 'COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_NRE'
}

export const ORGANIGRAMA: UnidadOrganizativa = {
	id: EOrganizacionUnidadesOrganizativas.RAIZ,
	unidadOrganizativa: '/',
	seleccionable: false,
	hijos: [
		{
			id: EOrganizacionUnidadesOrganizativas.COFEPRIS,
			unidadOrganizativa: 'COFEPRIS',
			hijos: [
				{
					id: EOrganizacionUnidadesOrganizativas.COFEPRIS_CSFS,
					unidadOrganizativa: 'Coordinación General del Sistema Federal Sanitario',
					seleccionable: false,
					hijos: [
						{
							id: EOrganizacionUnidadesOrganizativas.COFEPRIS_CSFS_DESP,
							unidadOrganizativa: 'Dirección Ejecutiva de Sistemas y Procesos',
							seleccionable: false
						}
					]
				},
				{
					id: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS,
					unidadOrganizativa: 'Comisión de Autorización Sanitaria',
					seleccionable: true,
					hijos: [
						{
							id: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE,
							unidadOrganizativa: 'Dirección Ejecutiva de Autorización de Productos y Establecimientos',
							seleccionable: true,
							hijos: [
								{
									id: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM,
									unidadOrganizativa: 'Subdirección Ejecutiva de Fármacos y Medicamentos',
									seleccionable: true,
									hijos: [
										{
											id: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_EC,
											unidadOrganizativa: 'Ensayos Clínicos',
											seleccionable: true,
											actores: [EActor.DICTAMINADOR_RESPONSABLE, EActor.DICTAMINADOR_ESTADISTICO, EActor.AUXILIAR_SOLO_LECTURA, EActor.COORDINADOR_COMITE_MOLECULAS_NUEVAS]
										},
										{
											id: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMA,
											unidadOrganizativa: 'Gerencia de Medicamentos Alopáticos',
											seleccionable: true,
											hijos: [
												{
													id: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMA_BBV,
													unidadOrganizativa: 'Biológicos, Biotecnológicos y Vacunas',
													seleccionable: true,
													actores: [EActor.DICTAMINADOR_QUIMICO_RESPONSABLE, EActor.DICTAMINADOR_MEDICO_RESPONSABLE]
												},
												{
													id: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMA_NR,
													unidadOrganizativa: 'Nuevos Registros',
													seleccionable: true,
													actores: [EActor.DICTAMINADOR_QUIMICO_RESPONSABLE, EActor.DICTAMINADOR_MEDICO_RESPONSABLE]
												},
												{
													id: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMA_MCR,
													unidadOrganizativa: 'Medicamentos con Registro',
													seleccionable: true,
													actores: [EActor.DICTAMINADOR_QUIMICO_RESPONSABLE, EActor.DICTAMINADOR_MEDICO_RESPONSABLE]
												},
												{
													id: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMA_FAEE,
													unidadOrganizativa: 'Fórmulas de Alimentacion Enteral Especializadas',
													seleccionable: true,
													actores: [EActor.DICTAMINADOR_QUIMICO_RESPONSABLE, EActor.DICTAMINADOR_MEDICO_RESPONSABLE]
												},
												{
													id: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMA_HUERFANOS,
													unidadOrganizativa: 'Medicamentos Huérfanos',
													seleccionable: true,
													actores: [EActor.DICTAMINADOR_QUIMICO_RESPONSABLE, EActor.DICTAMINADOR_MEDICO_RESPONSABLE]
												}
											]
										},
										{
											id: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMHHA,
											unidadOrganizativa: 'Gerencia de Medicamentos Herbolarios, Homeopáticos y Alternativos',
											seleccionable: true,
											actores: [EActor.DICTAMINADOR_QUIMICO_RESPONSABLE, EActor.DICTAMINADOR_MEDICO_RESPONSABLE]
										}
									]
								},
								{
									id: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SESSDM,
									unidadOrganizativa: 'Subdirección Ejecutiva de Servicios de Salud y Dispositivos Médicos',
									seleccionable: true,
									hijos: [
										{
											id: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH,
											unidadOrganizativa: 'Gerencia de Material de Curación, Equipo Médico, Prótesis y Productos Higiénicos',
											seleccionable: true,
											hijos: [
												{
													id: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_NRCI,
													unidadOrganizativa: 'Nuevos Registros Clase I',
													seleccionable: true,
													actores: [EActor.DICTAMINADOR_RESPONSABLE]
												},
												{
													id: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_NRCII,
													unidadOrganizativa: 'Nuevos Registros Clase II',
													seleccionable: true,
													actores: [EActor.DICTAMINADOR_RESPONSABLE]
												},
												{
													id: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_NRCIII,
													unidadOrganizativa: 'Nuevos Registros Clase III',
													seleccionable: true,
													actores: [EActor.DICTAMINADOR_RESPONSABLE]
												},
												{
													id: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_DCR,
													unidadOrganizativa: 'Dispositivos con Registro',
													seleccionable: true,
													actores: [EActor.DICTAMINADOR_RESPONSABLE]
												},
												{
													id: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_DP,
													unidadOrganizativa: 'Dispositivos Prórrogas',
													seleccionable: true,
													actores: [EActor.DICTAMINADOR_RESPONSABLE]
												},
												{
													id: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_NRE,
													unidadOrganizativa: 'Nuevos Registros Equivalencias',
													seleccionable: true,
													actores: [EActor.DICTAMINADOR_RESPONSABLE]
												}
											]
										}
									]
								}
							]
						}
					]
				}
			]
		}
	]
}

export function getUnidadOrganizativaById(id: EOrganizacionUnidadesOrganizativas | string, root: UnidadOrganizativa = ORGANIGRAMA): UnidadOrganizativa | null {
	const unidadOrganizativa = root.hijos?.find(uo => uo.id === (id as EOrganizacionUnidadesOrganizativas))
	if (unidadOrganizativa) {
		return unidadOrganizativa
	} else {
		if (root.hijos) {
			for (const uo of root.hijos) {
				const unidadOrganizativa = getUnidadOrganizativaById(id, uo)
				if (unidadOrganizativa) {
					return unidadOrganizativa
				}
			}
		}
	}
	return null
}

export function getDiccionarioActoresByUnidadOrganizativaId(id: EOrganizacionUnidadesOrganizativas, root: UnidadOrganizativa = ORGANIGRAMA): CatalogGenericData[] {
	const diccionario: CatalogGenericData[] = []

	const unidadOrganizativa = getUnidadOrganizativaById(id, root)
	if (unidadOrganizativa) {
		unidadOrganizativa.actores?.forEach(actor => {
			diccionario.push({
				key: actor,
				value: Actores[actor] as any
			})
		})
	}
	return diccionario
}

export function getDiccionarioCargosOrganizacion(): CatalogGenericData[] {
	const diccionario: CatalogGenericData[] = []
	Object.keys(ECargoUnidadOrganizativa).forEach(key => {
		diccionario.push({ key: key, value: DictonaryCargosUnidadOrganizativa[key as ECargoUnidadOrganizativa] as any })
	})
	diccionario.sort((a, b) => a.value.localeCompare(b.value))
	return diccionario
}
