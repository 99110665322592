/**
 * @name router
 * @author @tirsomartinezreyes
 * @version 2.0.3
 *
 * @description Exportación de rutas y niveles de acceso
 *
 * @changelog 2.0.3 - 21/ene/24 - @tirsomartinezreyes - Se integran rutas de RNIS
 * @changelog 2.0.2 - 06/nov/24 - @tirsomartinezreyes - Se ajustan props para consulta de llaves de pago
 * @changelog 2.0.1 - 30/sep/24/ - Se agregan rutas de Activos de información de dispositivos y medicamentos
 * @changelog 1.0.1 - 17/sep/24/ - Se agregan rutas para cofepagos
 *
 *
 */

import AdministradorLayout from 'src/layouts/AdministradorLayout.vue'
import PerfilLayout from 'src/layouts/PerfilLayout.vue'
import PortalLayout from 'src/layouts/PortalLayout.vue'
import SistemaLayout from 'src/layouts/SistemaLayout.vue'
import SitioLayout from 'src/layouts/SitioLayout.vue'
import SuperAdministradorLayout from 'src/layouts/SuperAdministradorLayout.vue'
import EditorActivosLayout from 'src/layouts/EditorActivosLayout.vue'
import { ACL, ACLItem } from 'src/router/acl'
import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router'

export interface IRouteWithMeta {
	path: string
	name?: string
	redirect?: string
	component?: any
	meta: ACLItem
	children?: IRouteWithMeta[]
	useRFC?: boolean
	props?: {
		default?: boolean
		[key: string]: any
	}
}

const routes: IRouteWithMeta[] = [
	{
		path: '/',
		redirect: '/sitio/home',
		meta: ACL['/']
	},
	{
		path: '/sitio',
		redirect: '/sitio/home',
		component: SitioLayout,
		meta: ACL['/sitio'],
		children: [
			{
				path: 'home',
				name: 'Home',
				component: () => import('src/pages/index.vue'),
				meta: ACL['/sitio/home']
			},
			{
				path: 'login',
				name: 'Ingresar',
				component: () => import('src/pages/LoginView.vue'),
				meta: ACL['/sitio/login'],
				props: {
					debug: false
				}
			},
			{
				path: 'logout',
				name: 'Cierre de sesión',
				component: () => import('src/pages/LogoutView.vue'),
				meta: ACL['/sitio/logout'],
				props: {
					debug: false
				}
			},
			{
				path: 'generador_credenciales',
				name: 'Generador de credenciales',
				component: () => import('src/components/Superadministrador/CredentialGenerator.vue'),
				props: {
					debug: import.meta.env.DEV
				},
				meta: ACL['/sitio/generador_credenciales']
			},
			{
				path: 'validacion_documentos/:uuid',
				name: 'Cotejo de documentos_params',
				component: () => import('src/components/Captcha/DocumentViewer.vue'),
				props: {
					debug: import.meta.env.DEV
				},
				meta: ACL['/sitio/validacion_documentos/:uuid']
			},
			{
				path: 'validacion_documentos',
				name: 'Cotejo de documentos',
				component: () => import('src/components/Captcha/DocumentViewer.vue'),
				props: {
					debug: import.meta.env.DEV
				},
				meta: ACL['/sitio/validacion_documentos']
			},
			{
				path: 'rnec',
				name: 'RNEC',
				component: () => import('src/components/RNEC/RNEC.vue'),
				props: {
					debug: import.meta.env.DEV
				},
				meta: ACL['/sitio/rnec']
			},
			{
				path: 'rnec/:uuid',
				name: 'RNEC / Ensayo Clínico',
				component: () => import('src/components/RNEC/RNEC.vue'),
				props: {
					debug: import.meta.env.DEV
				},
				meta: ACL['/sitio/rnec/:uuid']
			},
			{
				path: 'rnis',
				name: 'RNIS',
				component: () => import('src/components/RNIS/RNIS.vue'),
				props: {
					debug: import.meta.env.DEV
				},
				meta: ACL['/sitio/rnis']
			},
			{
				path: 'rnis/:uuid',
				name: 'RNIS / Insumo de Salud',
				component: () => import('src/components/RNIS/RNIS.vue'),
				props: {
					debug: import.meta.env.DEV
				},
				meta: ACL['/sitio/rnis/:uuid']
			}
		]
	},
	{
		path: '/administrador',
		redirect: '/administrador/bitacoras',
		component: AdministradorLayout,
		meta: ACL['/administrador'],
		children: [
			{
				path: 'usuarios',
				name: 'Usuarios ',
				component: () => import('src/components/Superadministrador/Usuarios.vue'),
				meta: ACL['/administrador/usuarios'],
				props: {
					debug: import.meta.env.DEV
				}
			},
			{
				path: 'usuarios/:uuid',
				name: 'AdminUsuarioID',
				component: () => import('src/components/Superadministrador/Usuario.vue'),
				props: {
					debug: import.meta.env.DEV
				},
				meta: ACL['/administrador/usuarios/:uuid']
			},
			{
				path: 'bitacoras',
				name: 'Bitácoras',
				component: () => import('src/components/Administrador/Bitacoras.vue'),
				meta: ACL['/administrador/bitacoras'],
				props: {
					debug: import.meta.env.DEV
				}
			},
			{
				path: 'reportes',
				name: 'Reportes Especiales',
				component: () => import('src/components/Administrador/ReportesAdministrador.vue'),
				meta: ACL['/administrador/reportes'],
				props: {
					debug: import.meta.env.DEV
				}
			},
			{
				path: 'cofepagos/importar',
				name: 'Importación de Llaves de Pago',
				component: () => import('src/components/Cofepagos/ImportarLlavesPago.vue'),
				meta: ACL['/administrador/cofepagos/importar'],
				props: {
					debug: import.meta.env.DEV
				}
			},
			{
				path: 'cofepagos/llaves',
				name: 'Consulta de Llaves de Pago',
				component: () => import('src/components/Cofepagos/ConsultarLlavesPago.vue'),
				meta: ACL['/administrador/cofepagos/llaves'],
				props: {
					debug: import.meta.env.DEV,
					mostrarEncabezado: true,
					mostrarVersion: true
				}
			},
			{
				path: 'cofepagos/transacciones',
				name: 'Transacciones',
				component: () => import('src/components/Cofepagos/ConsultarTransacciones.vue'),
				meta: ACL['/administrador/cofepagos/transacciones'],
				props: {
					debug: import.meta.env.DEV
				}
			},
			{
				path: 'formularios/:id',
				name: 'Generador de formularios_params',
				component: () => import('src/components/AdminFormularios.vue'),
				meta: ACL['/administrador/formularios/:id']
			},
			{
				path: 'formularios',
				name: 'Generador de formularios',
				component: () => import('src/components/AdminFormularios.vue'),
				meta: ACL['/administrador/formularios']
			},
			{
				path: 'plantillas/:id',
				name: 'Generador de plantilla_params',
				component: () => import('src/components/AdminPlantillas.vue'),
				meta: ACL['/administrador/plantillas/:id']
			},
			{
				path: 'plantillas',
				name: 'Generador de plantillas',
				component: () => import('src/components/AdminPlantillas.vue'),
				meta: ACL['/administrador/plantillas']
			},
			{
				path: 'ai',
				name: 'Prueba IAI',
				component: () => import('src/components/AdminRenec.vue'),
				meta: ACL['/administrador/ai']
			},
			{
				path: 'relacionFFCC',
				name: 'Relación FFCC',
				component: () => import('src/components/RelacionFormatoFormularioCampoCustomType/RelacionFormatoFormularioCampoCustomType.vue'),
				meta: ACL['/administrador/relacionFFCC']
			},
			{
				path: 'regex',
				name: 'Generador de expresiones regulares',
				component: () => import('src/components/AdminRegex.vue'),
				meta: ACL['/administrador/regex']
			},
			{
				path: 'tipoDeDatos',
				name: 'Tipos de datos',
				component: () => import('src/components/CatalogoTiposDatos.vue'),
				meta: ACL['/administrador/tipoDeDatos']
			},
			{
				path: 'buildertype',
				name: 'Administrador de tipos de datos para los formularios',
				component: () => import('src/components/AdminTiposDatos.vue'),
				meta: ACL['/administrador/buildertype']
			},
			{
				path: 'arbolRecursos',
				name: 'Árbol de recursos',
				component: () => import('src/components/ArbolRecursos/ArbolRecursos.vue'),
				meta: ACL['/administrador/arbolRecursos'],
				props: {
					debug: false
				}
			},
			{
				path: 'modo_operacion',
				name: 'Modo de Operación',
				component: () => import('src/components/Administrador/AdminModoOperacion.vue'),
				meta: ACL['/administrador/modo_operacion'],
				props: {
					debug: false
				}
			},
			{
				path: 'catalogos/rutas',
				name: 'Catálogo de Rutas',
				component: () => import('src/components/Superadministrador/Rutas.vue'),
				meta: ACL['/administrador/catalogos/rutas'],
				props: {
					debug: false
				}
			},
			{
				path: 'catalogos/funciones',
				name: 'Catálogo de Funciones',
				component: () => import('src/components/Superadministrador/Funciones.vue'),
				meta: ACL['/administrador/catalogos/funciones'],
				props: {
					debug: false
				}
			},
			{
				path: 'catalogos/homoclaves',
				name: 'Catálogo de Homoclaves',
				component: () => import('src/components/Superadministrador/CatalogoHomoclaves.vue'),
				meta: ACL['/administrador/catalogos/homoclaves'],
				props: {
					debug: false
				}
			}
		]
	},
	{
		path: '/editor_activos',
		component: EditorActivosLayout,
		meta: ACL['/editor_activos'],
		children: [
			{
				path: 'insumos_salud',
				name: 'Insumos de salud',
				component: () => import('src/components/Administrador/EditorActivosComponent.vue'),
				meta: ACL['/editor_activos/insumos_salud'],
				props: {
					debug: import.meta.env.DEV
				}
			},
			{
				path: 'ensayos_clinicos',
				name: 'Ensayos Clínicos',
				component: () => import('src/components/Administrador/EditorEnsayosClinicos.vue'),
				meta: ACL['/editor_activos/ensayos_clinicos'],
				props: {
					debug: import.meta.env.DEV
				}
			},
			{
				path: 'ensayos_clinicos/:uuid',
				name: 'Ensayo Clínico',
				component: () => import('src/components/Administrador/EditorEnsayosClinicos.vue'),
				meta: ACL['/editor_activos/ensayos_clinicos/:uuid'],
				props: {
					debug: import.meta.env.DEV
				}
			}
		]
	},
	{
		path: '/superadministrador',
		redirect: '/superadministrador/usuarios',
		component: SuperAdministradorLayout,
		meta: ACL['/superadministrador'],
		children: [
			{
				path: 'usuarios',
				name: 'Usuarios',
				component: () => import('src/components/Superadministrador/Usuarios.vue'),
				meta: ACL['/superadministrador/usuarios'],
				props: {
					debug: false
				}
			},
			{
				path: 'usuarios/nuevo',
				name: 'Agregar Usuario',
				component: () => import('src/components/Superadministrador/NuevoUsuario.vue'),
				props: {
					debug: import.meta.env.DEV
				},
				meta: ACL['/superadministrador/usuarios/nuevo']
			},
			{
				path: 'usuarios/:uuid',
				name: 'UsuarioID',
				component: () => import('src/components/Superadministrador/Usuario.vue'),
				props: {
					debug: import.meta.env.DEV
				},
				meta: ACL['/superadministrador/usuarios/:uuid']
			}
		]
	},
	{
		path: '/sistema',
		redirect: '/sistema/reportes',
		component: SistemaLayout,
		meta: ACL['/sistema'],
		children: [
			{
				path: 'reportes',
				name: 'Reportes',
				component: () => import('src/components/Reportes/Reportes.vue'),
				meta: ACL['/sistema/reportes']
			},
			{
				path: 'reportes/tramites_en_evaluacion',
				name: 'Trámites en evaluación',
				component: () => import('src/components/Reportes/TramitesEnEvaluacion.vue'),
				meta: ACL['/sistema/reportes/tramites_en_evaluacion']
			},
			{
				path: 'reportes/tramites_en_resolucion',
				name: 'Trámites en resolución',
				component: () => import('src/components/Reportes/TramitesEnResolucion.vue'),
				meta: ACL['/sistema/reportes/tramites_en_resolucion']
			},
			{
				path: 'reportes/tramites_en_firma',
				name: 'Trámites en firma',
				component: () => import('src/components/Reportes/TramitesEnFirma.vue'),
				meta: ACL['/sistema/reportes/tramites_en_firma']
			},
			{
				path: 'reportes/tramites_en_verificacion',
				name: 'Trámites en verificación',
				component: () => import('src/components/Reportes/TramitesEnVerificacion.vue'),
				meta: ACL['/sistema/reportes/tramites_en_verificacion']
			},
			{
				path: 'reportes/tramites_en_prevencion',
				name: 'Trámites en prevención',
				component: () => import('src/components/Reportes/TramitesEnPrevencion.vue'),
				meta: ACL['/sistema/reportes/tramites_en_prevencion']
			},
			{
				path: 'reportes/tramites_asignados',
				name: 'Trámites Asignados',
				component: () => import('src/components/Reportes/TramitesAsignados.vue'),
				meta: ACL['/sistema/reportes/tramites_asignados']
			},
			/*{
				path: 'notificaciones',
				name: 'Notificaciones',
				component: () => import('src/components/Blank/Blank.vue'),
				meta: ACL['/sistema/notificaciones']
			},
			{
				path: 'notificaciones/:idNotificacion',
				component: () => import('src/components/Blank/Blank.vue'),
				meta: ACL['/sistema/notificaciones/:idNotificacion']
			},
			*/
			{
				path: 'GRUPO/:RFC/HOMOCLAVE/:idHomoclave',
				component: () => import('src/components/Blank/Blank.vue'),
				meta: ACL['/sistema/GRUPO/:RFC/HOMOCLAVE/:idHomoclave']
			},
			{
				path: 'GRUPO/:RFC/HOMOCLAVE/:idHomoclave/MODALIDAD/:idModalidad',
				component: () => import('src/components/Blank/Blank.vue'),
				meta: ACL['/sistema/GRUPO/:RFC/HOMOCLAVE/:idHomoclave/MODALIDAD/:idModalidad']
			},
			{
				path: 'GRUPO/:RFC/HOMOCLAVE/:idHomoclave/MODALIDAD/:idModalidad/SOLICITUD/:idSolicitud',
				component: () => import('src/components/Homoclave/HomoclavePlaceHolder.vue'),
				meta: ACL['/sistema/GRUPO/:RFC/HOMOCLAVE/:idHomoclave/MODALIDAD/:idModalidad/SOLICITUD/:idSolicitud']
			}
		]
	},
	{
		path: '/portal',
		component: PortalLayout,
		meta: ACL['/portal'],
		children: [
			{
				path: '',
				component: () => import('src/components/Blank/Blank.vue'),
				meta: ACL['/portal']
			},
			{
				path: 'GRUPO/:RFC',
				name: 'Inicio',
				component: () => import('src/components/Inicio/Inicio.vue'),
				meta: ACL['/portal/GRUPO/:RFC']
			},
			{
				path: 'GRUPO/:RFC/permisos',
				name: 'Permisos',
				component: () => import('src/components/Permisos/Permisos.vue'),
				meta: ACL['/portal/GRUPO/:RFC/permisos'],
				props: {
					debug: false
				}
			},
			{
				path: 'GRUPO/:RFC/registros_sanitarios_medicamentos',
				name: 'Registros Sanitarios de Medicamentos',
				component: () => import('src/components/RegistrosSanitarios/RegistrosSanitariosMedicamentos.vue'),
				meta: ACL['/portal/GRUPO/:RFC/registros_sanitarios_medicamentos']
			},
			{
				path: 'GRUPO/:RFC/registros_sanitarios_dispositivos',
				name: 'Registros Sanitarios Dispositivos',
				component: () => import('src/components/RegistrosSanitarios/RegistrosSanitariosDispositivos.vue'),
				meta: ACL['/portal/GRUPO/:RFC/registros_sanitarios_dispositivos']
			},
			{
				path: 'GRUPO/:RFC/agregarPermiso',
				name: 'Agregar Permiso',
				component: () => import('src/components/Permisos/Nuevo.vue'),
				meta: ACL['/portal/GRUPO/:RFC/agregarPermiso'],
				props: {
					debug: false
				}
			},
			/*{
				path: 'GRUPO/:RFC/NOTIFICACION',
				name: 'Notificaciones',
				component: () => import('src/components/Blank/Blank.vue'),
				meta: ACL['/portal/GRUPO/:RFC/NOTIFICACION']
			},
			{
				path: 'GRUPO/:RFC/NOTIFICACION/:idNotificacion',
				component: () => import('src/components/Blank/Blank.vue'),
				meta: ACL['/portal/GRUPO/:RFC/NOTIFICACION/:idNotificacion']
			},
			{
				path: 'GRUPO/:RFC/informacion',
				name: 'Informacion',
				component: () => import('src/components/Blank/Blank.vue'),
				meta: ACL['/portal/GRUPO/:RFC/informacion']
			},*/
			{
				path: 'GRUPO/:RFC/HOMOCLAVE',
				name: 'Homoclaves',
				component: () => import('src/components/Homoclaves/Homoclaves.vue'),
				meta: ACL['/portal/GRUPO/:RFC/HOMOCLAVE']
			},
			{
				path: 'GRUPO/:RFC/HOMOCLAVE/:idHomoclave',
				component: () => import('src/components/Blank/Blank.vue'),
				meta: ACL['/portal/GRUPO/:RFC/HOMOCLAVE/:idHomoclave']
			},
			{
				path: 'GRUPO/:RFC/HOMOCLAVE/:idHomoclave/MODALIDAD/:idModalidad',
				component: () => import('src/components/Homoclave/Homoclave.vue'),
				meta: ACL['/portal/GRUPO/:RFC/HOMOCLAVE/:idHomoclave/MODALIDAD/:idModalidad'],
				props: {
					debug: import.meta.env.DEV
				}
			},
			{
				path: 'GRUPO/:RFC/HOMOCLAVE/:idHomoclave/MODALIDAD/:idModalidad/SOLICITUD/:idSolicitud',
				component: () => import('src/components/Homoclave/HomoclavePlaceHolder.vue'),
				meta: ACL['/portal/GRUPO/:RFC/HOMOCLAVE/:idHomoclave/MODALIDAD/:idModalidad/SOLICITUD/:idSolicitud']
			},
			{
				path: 'GRUPO/:RFC/nuevaSolicitud',
				component: () => import('src/components/Blank/Blank.vue'),
				meta: ACL['/portal/GRUPO/:RFC/nuevaSolicitud']
			}
		]
	},
	{
		path: '/perfil',
		redirect: '/perfil/cuenta',
		component: PerfilLayout,
		meta: ACL['/perfil'],
		children: [
			{
				path: 'cuenta',
				name: 'Cuenta',
				component: () => import('src/components/Cuenta/Cuenta.vue'),
				props: {
					debug: false
				},
				meta: ACL['/perfil/cuenta']
			},
			{
				path: 'sesion',
				name: 'Sesión',
				component: () => import('src/components/Blank/BlankPlaceHolder.vue'),
				props: {
					debug: false
				},
				meta: ACL['/perfil/sesion']
			}
		]
	},
	{
		path: '/:pathMatch(.*)*',
		meta: ACL['/:pathMatch(.*)*'],
		component: () => import('src/pages/NotFound.vue')
	}
]

const router = createRouter({
	history: createWebHistory((import.meta as any).env.BASE_URL),
	linkActiveClass: 'active',
	routes: routes as unknown as RouteRecordRaw[]
})
/*
router.beforeEach((to: any, from: any, next) => {
	const userStore = useUserStore()
	next()
})
*/

export default router
