/**
 * @module Firma
 * @author @tirsomartinezreyes
 * @version 1.1.4
 * @description Módulo para generar la cadena original de información y etapas del contexto de homoclave
 * Una cadena original inicia y termina por EFirmaDelimitador. DELIMITADOR_INICIO_FIN_CADENA (||)
 * El contenido de la cadena original son parejas llave:valor separadas por EFirmaDelimitador.DELIMITADOR_PAREJAS (|)
 * Cada pareja llave:valor tiene una llave y un valor separados por EFirmaDelimitador.DELIMITADOR_LLAVE_VALOR (:)
 * El valor de una pareja llave:valor puede ser un valor simple (STRING,NUMBER,BOOLEAN,DATE,ENUM) o una tabla (TABLE,FILE,ARRAY)
 * Un valor simple es un valor de texto
 * Las tablas inican y terminan con EFirmaDelimitador.DELIMITADOR_VALOR_INICIO_TABLA ({) y EFirmaDelimitador.DELIMITADOR_VALOR_FIN_TABLA (})
 * Una tabla es un conjunto de filas separadas por EFirmaDelimitador.DELIMITADOR_VALOR_INICIO_FILA ([) y EFirmaDelimitador.DELIMITADOR_VALOR_FIN_FILA (])
 * Se recomienda que la primera contenga el nombre de los encabezados de los campos de la tabla, por ejemplo: [<NOMBRE><TAMAÑO><SHA256>]
 * Una fila es un conjunto de celdas separadas por EFirmaDelimitador.DELIMITADOR_VALOR_INICIO_CELDA (<) y EFirmaDelimitador.DELIMITADOR_VALOR_FIN_CELDA (>)
 * Una celda es un valor simple
 * Cuando no existe un dato en una celda o un valor simple, se utiliza EFirmaDelimitador.DELIMITADOR_VALOR_VACIO («SIN_DATO»)
 * Ejemplo de cadena:  ||NOMBRE:JUAN|PRIMER APELLIDO:LOPEZ|TELÉFONO:«SIN_DATO»|CALLE:AV. MÉXICO|ARCHIVOS ADJUNTOS:{[<NOMBRE><TAMAÑO><SHA256>][<a.pdf><12547><ACB257415CDE258AC>]}||
 * Es muy importante que en la construcción de la cadena original se utilicen los valores normalizados de los datos, y que los datos de celda y los datos simples pasen por el filtro wrapValor
 *
 * Notas de la versión
 * @changelog 1.1.4 - 19/sep/24 - Se agrega cantidad de llaves para Cadena original de Cofepagos @tirsomartinezreyes
 * @changelog 1.1.3 - 05/sep/24 - Se agrega la generación de firma para Cofepagos @tirsomartinezreyes
 * @changelog 1.1.2 - 05/sep/24 - Se agregan las secciones de modificaciones de medicamentos y dispositivos a la cadena original de insumos para la salud @tirsomartinezreyes
 * @changelog 1.1.1 Se agrega el dossier y las llaves de pago a la cadena original de insumos para la salud
 * @changelog 1.1.0 Se agregó la costante de reemplazos de caracteeres reservados y se integro en la función de filtrar caracteres no válidos, pra evitar perder información en la cadena original
 */
import { IHomoclaveContext } from 'cofepris-typesafe/Types/Homoclave'
import { CTD } from 'cofepris-typesafe/Modules/CTD'
import { CHomoclavesEnsayosClinicos, CHomoclavesInsumosSalud, EHomoclaves } from 'cofepris-typesafe/Modules/Homoclaves'
import {
	IMappingDefinicionHomoclave,
	IMappingDefinicionSeccion,
	getDefinicionMappingFromContextoByFase,
	getDefinicionEtiquetaFromFormulario,
	getDefinicionEtiquetaFromCampo,
	getDefinicionNivelAccesoFromCampo
} from './Formatos'
import {
	getDatosFromContextoByFase,
	getFaseEvaluacionFromContexto,
	getDatosValorNormalizadoFromCampo,
	getDatosMapping,
	EDatosFase,
	IDato,
	EDatosNodo,
	IMappingDatos,
	IDatoValorNormalizado
} from './Datos'

import { ECofepagosTransaccionTipo, ICofepagosLlavePagoBase } from 'cofepris-typesafe/Modules/Cofepagos'

import { IEvaluacion, getEvaluacionMapping, getEvaluacionFromContextoByFase, getEvaluacionValorNormalizadoFromComentario, IEvaluacionItem, getIndiceUltimaEvaluacionFromContexto } from './Evaluacion'

import { NIVEL_ACCESO } from './NivelAcceso'
import { ConfidencialidadTestarByNivelAccesoContexto } from './Confidencialidad'

import { CUSTOM_TYPE } from 'cofepris-typesafe/src/primitives/IPrimitive'
import ICampoFormulario from 'cofepris-typesafe/src/forms/ICampoFormulario'
import { IFormulariosModificaciones, IModificacionSeccionOficio } from 'cofepris-typesafe/Modules/ModificacionesMedicamentos'
import { IFormulariosModificacionesDispositivos, IModificacionSeccionOficioDispositivo } from 'cofepris-typesafe/Modules/ModificacionesDispositivos'
import { EAIInsumoSaludTipo, IAIInsumoSalud } from './InsumoSalud'
import stringify from 'json-stringify-deterministic'

/**
 * @description Fases del proceso de homoclave en las que existen datos firmables
 */
export enum EFirmaFase {
	SOLICITUD = 'solicitud',
	EVALUACION = 'evaluacion',
	RESOLUCION = 'resolucion',
	RESPUESTA_PREVENCION = 'respuesta_prevencion'
}

export enum EFirmaDelimitador {
	DELIMITADOR_INICIO_FIN_CADENA = '||', //Delimitador de inicio y fin de cadena original
	DELIMITADOR_SECCION = '~', //Delimitador de inicio y fin de sección en cadena original
	DELIMITADOR_PAREJAS = '|', //Delimitador de triadas "LLAVE:NIVEL_ACCESO:VALOR" y/o secciones
	DELIMITADOR_LLAVE_VALOR = ':', //Delimitador de los elementos de las triadas llave, NIVEL_ACCESO y valor
	DELIMITADOR_VALOR_INICIO_TABLA = '{', //Delimitador de inicio de tabla
	DELIMITADOR_VALOR_FIN_TABLA = '}', //Delimitador de fin de tabla
	DELIMITADOR_VALOR_INICIO_FILA = '[', //Delimitador de inicio de fila
	DELIMITADOR_VALOR_FIN_FILA = ']', //Delimitador de fin de fila
	DELIMITADOR_VALOR_INICIO_CELDA = '<', //Delimitador de inicio de celda
	DELIMITADOR_VALOR_FIN_CELDA = '>', //Delimitador de fin de celda
	DELIMITADOR_VALOR_INICIO_CABECERA = '«', //Delimitador de inicio de cabecera de columna
	DELIMITADOR_VALOR_FIN_CABECERA = '»', //Delimitador de fin de cabecera de columna
	DELIMITADOR_VALOR_VACIO = '«SIN_DATO»' //Sustituto de valor vacío
}
//Ejemplo de uso:
// ||~RESOLUCIÓN~|TIPO DE DOCUMENTO:PUBLICO:RESOLUCION|HOMOCLAVE:PUBLICO:COFEPRIS-09-012|MODALIDAD:PUBLICO:A|~INTERVENCIONES DEL ESTUDIO (AUTORIZADO)~|INTERVENCIONES DEL ESTUDIO (AUTORIZADO):PUBLICO:{[«Medicamento»«Frecuencia»][<2 tab letas de PF 07321332 150mg + 1 tableta de Ritonavir 100mg cada 12h><5 días (10 dosis totales)>][<1 tableta de PF 07321332 150mg + 1 tableta de Ritonavir 100mg cada 12h><5 días (10 dosis totales)>]}|CONSENTIMIENTO INFORMADO:PUBLICO:«SIN_DATO»||

export const CFirmaDelimitadorRemplazo: { [key in keyof typeof EFirmaDelimitador]: string } = {
	DELIMITADOR_INICIO_FIN_CADENA: '(doble pipe)', //Delimitador de inicio y fin de cadena original
	DELIMITADOR_SECCION: '(virgulilla)', //Delimitador de inicio y fin de sección en cadena original
	DELIMITADOR_PAREJAS: '(pipe)', //Delimitador de triadas "LLAVE:NIVEL_ACCESO:VALOR" y/o secciones
	DELIMITADOR_LLAVE_VALOR: '(dos puntos)', //Delimitador de los elementos de las triadas llave, NIVEL_ACCESO y valor
	DELIMITADOR_VALOR_INICIO_TABLA: '(llave de apertura)', //Delimitador de inicio de tabla
	DELIMITADOR_VALOR_FIN_TABLA: '(llave de cierre)', //Delimitador de fin de tabla
	DELIMITADOR_VALOR_INICIO_FILA: '(corchete de apertura)', //Delimitador de inicio de fila
	DELIMITADOR_VALOR_FIN_FILA: '(corchete de cierre)', //Delimitador de fin de fila
	DELIMITADOR_VALOR_INICIO_CELDA: '(menor que)', //Delimitador de inicio de celda
	DELIMITADOR_VALOR_FIN_CELDA: '(mayor que)', //Delimitador de fin de celda
	DELIMITADOR_VALOR_INICIO_CABECERA: '(comilla española de apertura)', //Delimitador de inicio de cabecera de columna
	DELIMITADOR_VALOR_FIN_CABECERA: '(comilla española de cierre)', //Delimitador de fin de cabecera de columna
	DELIMITADOR_VALOR_VACIO: EFirmaDelimitador.DELIMITADOR_VALOR_VACIO //Sustituto de valor vacío
}

export enum EFirmaTipoDocumento {
	SOLICITUD = 'SOLICITUD',
	DESISTIMIENTO = 'DESISTIMIENTO',
	EVALUACION = 'EVALUACION',
	RESPUESTA_PREVENCION = 'RESPUESTA_PREVENCION',
	RESOLUCION = 'RESOLUCION'
}

export enum EFirmaDisclaimer {
	SOLICITUD = 'Declaro bajo protesta decir verdad que cumplo con los requisitos y normatividad aplicable, sin que me eximan de que la autoridad sanitaria verifique su cumplimiento, esto sin perjuicio de las sanciones en que puedo incurrir por falsedad de declaraciones dadas a una autoridad. Y acepto que las notificaciónes de este trámite se realicen a través de medios electrónicos de acuerdo a los términos y condiciones de la plataforma.(Artículo 35 fracción II de la Ley Federal de Procedimiento Administrativo).Estoy de acuerdo con que datos o documentos anexos a la solicitud pueden contener información confidencial y que podría hacerse pública.He leído y estoy de acuerdo con los términos y condiciones del uso de la plataforma',
	DESISTIMIENTO = 'Declaro que por propio derecho y por así convenir a mis intereses como solicitante, desisto del trámite con el conocimiento pleno que esta acción es definitiva, no puede deshacerse y que cualquier costo asociado al trámite no será recuperable.',
	EVALUACION = 'Manifiesto que los datos asentados como parte del proceso de evaluación y dictamen, así como los documentos anexos se encuentran de conformidad con los procedimientos operativos vigentes y fueron validados con mi rubrica electrónica',
	RESPUESTA_PREVENCION = 'Declaro bajo protesta decir verdad que cumplo con los requisitos y normatividad aplicable, sin que me eximan de que la autoridad sanitaria verifique su cumplimiento, esto sin perjuicio de las sanciones en que puedo incurrir por falsedad de declaraciones dadas a una autoridad. Y acepto que las notificaciónes de este trámite se realicen a través de medios electrónicos de acuerdo a los términos y condiciones de la plataforma.(Artículo 35 fracción II de la Ley Federal de Procedimiento Administrativo).Estoy de acuerdo con que datos o documentos anexos a la solicitud pueden contener información confidencial y que podría hacerse pública.He leído y estoy de acuerdo con los términos y condiciones del uso de la plataforma.',
	RESOLUCION = 'Con fundamento en los artículos Décimo Primero, fracción I y VIII del Acuerdo por el que se delegan las facultades que se señalan, en los Órganos Administrativos que en el mismo se indican de la Comisión Federal para la Protección Contra Riesgos Sanitarios, publicado en el Diario Oficial de la Federación el 07 de abril de 2010'
}

/**
 * @name IFirmaES256
 * @description Firma electrónica del activo de información de insumo para la salud con algoritmo ES256
 */
export interface IFirmaES256 {
	algoritmo: 'ES256'
	cadenaOriginal: string
	firmante: string
	sha256: string
	idCredencial: string
	llavePublica: string
	firma: string
	autenticador: IFirmaAutenticador
}
/**
 * @name IFirmaAutenticador
 * @description Firma electrónica del activo de información de insumo para la salud con algoritmo ES256
 */
export interface IFirmaAutenticador {
	origin: string
	rpIdHash: string
	flags: string
	signCount: string
	clientDataJSON: string
	clientDataHash: string
}

/**
 * @description Genera cadena original de solicitud
 */
export const FirmaGeneraCadenaOriginalSolicitud = function (context: IHomoclaveContext): string {
	let response: string = ''
	try {
		const parejas: string[] = []

		//SOLICITUD
		parejas.push(wrapSeccion('SOLICITUD'))
		parejas.push(wrapParejaLlaveValor('TIPO DE DOCUMENTO', wrapValor(EFirmaTipoDocumento.SOLICITUD), NIVEL_ACCESO.PUBLICO, NIVEL_ACCESO.CONFIDENCIAL))
		parejas.push(wrapParejaLlaveValor('HOMOCLAVE', wrapValor(context.homoclave?.homoclave as string), NIVEL_ACCESO.PUBLICO, NIVEL_ACCESO.CONFIDENCIAL))
		parejas.push(wrapParejaLlaveValor('MODALIDAD', wrapValor(context.modalidad?.modalidad as string), NIVEL_ACCESO.PUBLICO, NIVEL_ACCESO.CONFIDENCIAL))
		parejas.push(wrapParejaLlaveValor('RFC SOLICITANTE', wrapValor(context.metadatos?.grupo as string), NIVEL_ACCESO.PUBLICO, NIVEL_ACCESO.CONFIDENCIAL))
		parejas.push(wrapParejaLlaveValor('SOLICITUD', wrapValor(context.metadatos?.idSolicitud as string), NIVEL_ACCESO.PUBLICO, NIVEL_ACCESO.CONFIDENCIAL))

		//DATOS

		//ENSAYOS CLÍNICOS
		if (CHomoclavesEnsayosClinicos.includes(context.homoclave?.homoclave as EHomoclaves)) {
			const definicionMapping: IMappingDefinicionHomoclave = getDefinicionMappingFromContextoByFase(context, EDatosFase.SOLICITUD)
			const datosMapping: IMappingDatos = getDatosMapping(getDatosFromContextoByFase(context, EDatosFase.SOLICITUD, EDatosNodo.DATOS))
			const secciones: IMappingDefinicionSeccion[] = Object.values(definicionMapping.secciones).sort((a, b) => a.homoclaveFormatoOrden - b.homoclaveFormatoOrden)

			secciones.forEach(seccion => {
				seccion.seccion.formularios?.forEach(formulario => {
					if (typeof formulario === 'object') {
						parejas.push(wrapSeccion(getDefinicionEtiquetaFromFormulario(formulario)))
						formulario.campos?.forEach(campo => {
							const valorNormalizado = getValorNormalizadoFromCampo(campo, datosMapping, 'DATO_CAMPO')
							parejas.push(wrapParejaLlaveValor(getDefinicionEtiquetaFromCampo(campo), valorNormalizado.valorNormalizado, getDefinicionNivelAccesoFromCampo(campo), NIVEL_ACCESO.CONFIDENCIAL))
						})
					}
				})
			})
		}

		//INSUMOS PARA LA SALUD
		if (CHomoclavesInsumosSalud.includes(context.homoclave?.homoclave as EHomoclaves)) {
			console.log('solicitud', context.solicitud?.datos?.insumosSalud?.insumoSalud)
			const sha256ArchivosDossier: string[] = []
			const llavesDePago: string[] = []
			const seccionesOficioModificadas: string[] = []
			const modificaciones: string[] = []
			const clasesDispositivos: string[] = []
			const formulas: string[] = []
			const aditivos: string[] = []

			//INSUMO SALUD
			parejas.push(wrapSeccion('INSUMO SALUD'))
			parejas.push(wrapParejaLlaveValor('ID ACTIVO INFORMACIÓN', wrapValor(context.solicitud?.datos?.insumosSalud?.insumoSalud?.idActivoInformacion), NIVEL_ACCESO.INTERNO, NIVEL_ACCESO.INTERNO))
			parejas.push(wrapParejaLlaveValor('TIPO INSUMO SALUD', wrapValor(context.solicitud?.datos?.insumosSalud?.insumoSalud?.tipoInsumoSalud), NIVEL_ACCESO.INTERNO, NIVEL_ACCESO.INTERNO))
			parejas.push(
				wrapParejaLlaveValor('DIRECCIÓN DEL TITULAR', wrapValor(context.solicitud?.datos?.insumosSalud?.insumoSalud?.datosGenerales?.direccionTitular), NIVEL_ACCESO.INTERNO, NIVEL_ACCESO.INTERNO)
			)
			parejas.push(
				wrapParejaLlaveValor(
					'DIRECCIÓN DEL REPRESENTANTE',
					wrapValor(context.solicitud?.datos?.insumosSalud?.insumoSalud?.datosGenerales?.direccionRepresentante),
					NIVEL_ACCESO.INTERNO,
					NIVEL_ACCESO.INTERNO
				)
			)

			parejas.push(
				wrapParejaLlaveValor(
					'ÚLTIMO OFICIO AUTORIZADO',
					wrapValor(context.solicitud?.datos?.insumosSalud?.insumoSalud?.datosGenerales?.ultimoOficioAutorizadoRegistroSanitario),
					NIVEL_ACCESO.INTERNO,
					NIVEL_ACCESO.INTERNO
				)
			)

			parejas.push(
				wrapParejaLlaveValor('DENOMINACIÓN GENÉRICA', wrapValor(context.solicitud?.datos?.insumosSalud?.insumoSalud?.datosGenerales?.denominacionGenerica), NIVEL_ACCESO.INTERNO, NIVEL_ACCESO.INTERNO)
			)

			//MEDICAMENTO
			if (context.solicitud?.datos?.insumosSalud?.insumoSalud?.tipoInsumoSalud == EAIInsumoSaludTipo.MEDICAMENTO) {
				parejas.push(wrapSeccion('MEDICAMENTO'))
				parejas.push(
					wrapParejaLlaveValor(
						'CLASE MEDICAMENTO LGS262',
						context.solicitud?.datos?.insumosSalud?.insumoSalud?.datosGenerales?.claseMedicamentoLGS226 as string,
						NIVEL_ACCESO.INTERNO,
						NIVEL_ACCESO.INTERNO
					)
				)

				parejas.push(
					wrapParejaLlaveValor(
						'FORMA FARMACÉUTICA',
						context.solicitud?.datos?.insumosSalud?.insumoSalud?.datosGenerales?.formaFarmaceuticaMedicamento as string,
						NIVEL_ACCESO.INTERNO,
						NIVEL_ACCESO.INTERNO
					)
				)

				//Secciones del Oficio
				if (context.solicitud?.datos?.insumosSalud?.medicamento?.modificacionesMedicamentoSeccionesOficio) {
					context.solicitud?.datos?.insumosSalud?.medicamento?.modificacionesMedicamentoSeccionesOficio.forEach(seccion => {
						seccionesOficioModificadas.push(wrapCelda(seccion))
					})
				}

				//Modificaciones
				if (context.solicitud?.datos?.insumosSalud?.medicamento?.formatosModificacionesMedicamento?.formatos) {
					const formatosModificaciones: IFormulariosModificaciones = context.solicitud?.datos?.insumosSalud?.medicamento?.formatosModificacionesMedicamento?.formatos
					delete (formatosModificaciones as any).otrosCambios
					Object.values(formatosModificaciones).forEach((formato: IModificacionSeccionOficio) => {
						if (context.solicitud?.datos?.insumosSalud?.medicamento?.modificacionesMedicamentoSeccionesOficio?.includes(formato?.titulo)) {
							formato.datos?.forEach(dato => {
								const celdas: string[] = []
								celdas.push(wrapCelda(formato.titulo))
								celdas.push(wrapCelda(dato.dice))
								celdas.push(wrapCelda(dato.debeDecir))
								modificaciones.push(wrapFila(celdas))
							})

							if (formato.formulas) {
								formato.formulas.forEach(formula => {
									const celdasFormulas: string[] = []
									celdasFormulas.push(wrapCelda(formula.nombre))
									celdasFormulas.push(wrapCelda(formula.proporcion))
									celdasFormulas.push(wrapCelda(formula.commentario))
									celdasFormulas.push(wrapCelda(formula.changeCountry))
									formula.aditivos.forEach(aditivo => {
										const celdasAditivo: string[] = []
										celdasAditivo.push(wrapCelda(formula.nombre))
										celdasAditivo.push(wrapCelda(aditivo.aditivo))
										celdasAditivo.push(wrapCelda(aditivo.unidaMedidad))
										celdasAditivo.push(wrapCelda(aditivo.cantidad.dice))
										celdasAditivo.push(wrapCelda(aditivo.cantidad.debeDecir))
										celdasAditivo.push(wrapCelda(aditivo.notaEspecifica.dice))
										celdasAditivo.push(wrapCelda(aditivo.notaEspecifica.debeDecir))
										aditivos.push(wrapFila(celdasAditivo))
									})
									formulas.push(wrapFila(celdasFormulas))
								})
							}
						}
					})
				}

				if (context.solicitud?.datos?.insumosSalud?.medicamento?.dossier) {
					//DOSSIER
					const ctd = new CTD(context.solicitud?.datos?.insumosSalud?.medicamento?.dossier)
					ctd.raiz.obtenerArchivos().forEach(archivo => {
						sha256ArchivosDossier.push(wrapCelda(archivo.sha256.substring(0, 7)))
					})
				}

				//LLAVES DE PAGO
				context.solicitud?.datos?.insumosSalud?.medicamento?.llavesPago?.forEach(llavePago => {
					const celdas: string[] = []
					celdas.push(wrapCelda(llavePago.llave))
					celdas.push(wrapCelda(llavePago.monto))
					llavesDePago.push(wrapFila(celdas))
				})
			}

			//DISPOSITIVO
			if (context.solicitud?.datos?.insumosSalud?.insumoSalud?.tipoInsumoSalud == EAIInsumoSaludTipo.DISPOSITIVO) {
				parejas.push(wrapSeccion('DISPOSITIVO'))
				if (context.solicitud?.datos?.insumosSalud?.insumoSalud?.datosGenerales?.claseDispositivoRIS83) {
					context.solicitud?.datos?.insumosSalud?.insumoSalud?.datosGenerales?.claseDispositivoRIS83.forEach(clase => {
						clasesDispositivos.push(wrapCelda(clase))
					})
				}
				if (seccionesOficioModificadas.length) {
					parejas.push(wrapParejaLlaveValor('CLASE DISPOSITIVO RIS83', wrapFila(clasesDispositivos), NIVEL_ACCESO.INTERNO, NIVEL_ACCESO.INTERNO))
				}

				parejas.push(
					wrapParejaLlaveValor(
						'CATEGORÍA DISPOSITIVO LGS262',
						context.solicitud?.datos?.insumosSalud?.insumoSalud?.datosGenerales?.categoriaDispositivoLGS262 as string,
						NIVEL_ACCESO.INTERNO,
						NIVEL_ACCESO.INTERNO
					)
				)

				//Modificaciones
				if (context.solicitud?.datos?.insumosSalud?.dispositivo?.formatosModificacionesDispositivo?.formatos) {
					const formatosModificaciones: IFormulariosModificacionesDispositivos | undefined = context.solicitud?.datos?.insumosSalud?.dispositivo?.formatosModificacionesDispositivo?.formatos
					delete (formatosModificaciones as any).otrosCambios
					if (formatosModificaciones) {
						Object.values(formatosModificaciones).forEach((formato: IModificacionSeccionOficioDispositivo) => {
							if (context.solicitud?.datos?.insumosSalud?.dispositivo?.modificacionesDispositivosSecionesOficio?.includes(formato.titulo)) {
								formato.datos?.forEach(dato => {
									const celdas: string[] = []
									celdas.push(wrapCelda(formato.titulo))
									celdas.push(wrapCelda(dato.dice))
									celdas.push(wrapCelda(dato.debeDecir))
									modificaciones.push(wrapFila(celdas))
								})
							}
						})
					}
				}

				//SECCIONES DEL OFICIO MODIFICADAS
				if (context.solicitud?.datos?.insumosSalud?.dispositivo?.modificacionesDispositivosSecionesOficio) {
					context.solicitud?.datos?.insumosSalud?.dispositivo?.modificacionesDispositivosSecionesOficio.forEach(seccion => {
						seccionesOficioModificadas.push(wrapCelda(seccion))
					})
				}

				//DOSSIER
				if (context.solicitud?.datos?.insumosSalud?.dispositivo?.dossier) {
					const ctd = new CTD(context.solicitud?.datos?.insumosSalud?.dispositivo?.dossier)
					ctd.raiz.obtenerArchivos().forEach(archivo => {
						sha256ArchivosDossier.push(wrapValor(archivo.sha256))
					})
				}

				context.solicitud?.datos?.insumosSalud?.dispositivo?.llavesPago?.forEach(llavePago => {
					const celdas: string[] = []
					celdas.push(wrapCelda(llavePago.llave))
					celdas.push(wrapCelda(llavePago.monto))
					llavesDePago.push(wrapFila(celdas))
				})
			}

			//SECCIONES DEL OFICIO MODIFICADAS
			if (seccionesOficioModificadas.length) {
				parejas.push(wrapParejaLlaveValor('SECCIONES MODIFICADAS OFICIO', wrapFila(seccionesOficioModificadas), NIVEL_ACCESO.INTERNO, NIVEL_ACCESO.INTERNO))
			}

			//MODIFICACIONES
			if (modificaciones.length) {
				modificaciones.unshift(wrapFila([wrapCabecera('SECCIÓN'), wrapCabecera('DICE'), wrapCabecera('DEBE DECIR')]))
				parejas.push(wrapParejaLlaveValor('MODIFICACIONES', wrapTabla(modificaciones), NIVEL_ACCESO.INTERNO, NIVEL_ACCESO.INTERNO))
			}

			//FORMULAS
			if (formulas.length) {
				formulas.unshift(wrapFila([wrapCabecera('NOMBRE'), wrapCabecera('PROPORCIÓN'), wrapCabecera('COMENTARIO'), wrapCabecera('CAMBIO DE PAÍS')]))
				parejas.push(wrapParejaLlaveValor('FÓRMULAS', wrapTabla(formulas), NIVEL_ACCESO.INTERNO, NIVEL_ACCESO.INTERNO))
			}

			//ADITIVOS
			if (aditivos.length) {
				aditivos.unshift(
					wrapFila([
						wrapCabecera('NOMBRE'),
						wrapCabecera('ADITIVO'),
						wrapCabecera('UNIDAD DE MEDIDA'),
						wrapCabecera('CANTIDAD DICE'),
						wrapCabecera('CANTIDAD DEBE DECIR'),
						wrapCabecera('NOTA ESPECÍFICA DICE'),
						wrapCabecera('NOTA ESPECÍFICA DEBE DECIR')
					])
				)
				parejas.push(wrapParejaLlaveValor('ADITIVOS', wrapTabla(aditivos), NIVEL_ACCESO.INTERNO, NIVEL_ACCESO.INTERNO))
			}

			//DOSSIER
			if (sha256ArchivosDossier.length) {
				parejas.push(wrapParejaLlaveValor('SHA256 CORTO ARCHIVOS DOSSIER', wrapFila(Array.from(new Set(sha256ArchivosDossier))), NIVEL_ACCESO.INTERNO, NIVEL_ACCESO.INTERNO))
			}

			//LLAVES DE PAGO
			if (llavesDePago.length) {
				llavesDePago.unshift(wrapFila([wrapCabecera('LLAVE DE PAGO'), wrapCabecera('MONTO')]))
				parejas.push(wrapParejaLlaveValor('LLAVES DE PAGO', wrapTabla(llavesDePago), NIVEL_ACCESO.INTERNO, NIVEL_ACCESO.INTERNO))
			}
		}

		//DECLARACIONES
		parejas.push(wrapSeccion('DECLARACIONES'))
		parejas.push(wrapParejaLlaveValor('DECLARACIÓN DE SOLICITUD', wrapValor(EFirmaDisclaimer.SOLICITUD), NIVEL_ACCESO.PUBLICO, NIVEL_ACCESO.CONFIDENCIAL))

		response = wrapCadenaOriginal(wrapParejasLlaveValor(parejas))
	} catch (e) {
		console.error('Error al generar cadena original de solicitud', e)
	}
	return response
}

/**
 * @description Genera cadena original de desistimiento
 */
export const FirmaGeneraCadenaOriginalDesistimiento = function (context: IHomoclaveContext): string {
	let response: string = ''
	try {
		const parejas: string[] = []
		//DESISTIMIENTO
		parejas.push(wrapSeccion('DESISTIMIENTO'))
		parejas.push(wrapParejaLlaveValor('TIPO DE DOCUMENTO', wrapValor(EFirmaTipoDocumento.DESISTIMIENTO), NIVEL_ACCESO.PUBLICO, NIVEL_ACCESO.PUBLICO))
		parejas.push(wrapParejaLlaveValor('HOMOCLAVE', wrapValor(context.homoclave?.homoclave as string), NIVEL_ACCESO.PUBLICO, NIVEL_ACCESO.PUBLICO))
		parejas.push(wrapParejaLlaveValor('MODALIDAD', wrapValor(context.modalidad?.modalidad as string), NIVEL_ACCESO.PUBLICO, NIVEL_ACCESO.PUBLICO))
		parejas.push(wrapParejaLlaveValor('RFC SOLICITANTE', wrapValor(context.metadatos?.grupo as string), NIVEL_ACCESO.PUBLICO, NIVEL_ACCESO.PUBLICO))
		parejas.push(wrapParejaLlaveValor('SOLICITUD', wrapValor(context.metadatos?.idSolicitud as string), NIVEL_ACCESO.PUBLICO, NIVEL_ACCESO.PUBLICO))
		parejas.push(wrapParejaLlaveValor('TRAMITE', wrapValor(context.metadatos?.idTramite as string), NIVEL_ACCESO.PUBLICO, NIVEL_ACCESO.PUBLICO))

		//DECLARACIONES
		parejas.push(wrapSeccion('DECLARACIONES'))
		parejas.push(wrapParejaLlaveValor('DECLARACIÓN', wrapValor(EFirmaDisclaimer.DESISTIMIENTO), NIVEL_ACCESO.PUBLICO, NIVEL_ACCESO.PUBLICO))

		response = wrapCadenaOriginal(wrapParejasLlaveValor(parejas))
	} catch (e) {
		console.error('Error al generar cadena original de desistimiento', e)
	}
	return response
}

/**
 * @description Genera cadena original de evaluación
 */
export const FirmaGeneraCadenaOriginalEvaluacion = function (context: IHomoclaveContext, indexEvaluacion?: number): string {
	let response: string = ''
	try {
		const parejas: string[] = []
		if (indexEvaluacion == undefined) {
			indexEvaluacion = getIndiceUltimaEvaluacionFromContexto(context) ?? 0
		}
		//EVALUACIÓN
		parejas.push(wrapSeccion('EVALUACIÓN'))
		parejas.push(wrapParejaLlaveValor('TIPO DE DOCUMENTO', wrapValor(EFirmaTipoDocumento.EVALUACION), NIVEL_ACCESO.PUBLICO, NIVEL_ACCESO.RESTRINGIDO))
		parejas.push(wrapParejaLlaveValor('HOMOCLAVE', wrapValor(context.homoclave?.homoclave as string), NIVEL_ACCESO.PUBLICO, NIVEL_ACCESO.RESTRINGIDO))
		parejas.push(wrapParejaLlaveValor('MODALIDAD', wrapValor(context.modalidad?.modalidad as string), NIVEL_ACCESO.PUBLICO, NIVEL_ACCESO.RESTRINGIDO))
		parejas.push(wrapParejaLlaveValor('RFC SOLICITANTE', wrapValor(context.metadatos?.grupo as string), NIVEL_ACCESO.PUBLICO, NIVEL_ACCESO.RESTRINGIDO))
		parejas.push(wrapParejaLlaveValor('SOLICITUD', wrapValor(context.metadatos?.idSolicitud as string), NIVEL_ACCESO.PUBLICO, NIVEL_ACCESO.RESTRINGIDO))
		parejas.push(wrapParejaLlaveValor('TRAMITE', wrapValor(context.metadatos?.idTramite as string), NIVEL_ACCESO.PUBLICO, NIVEL_ACCESO.RESTRINGIDO))
		parejas.push(wrapParejaLlaveValor('NÚMERO DE EVALUACIÓN', wrapValor((indexEvaluacion + 1).toString()), NIVEL_ACCESO.PUBLICO, NIVEL_ACCESO.RESTRINGIDO))

		const faseEvaluacion = getFaseEvaluacionFromContexto(context, indexEvaluacion)
		if (faseEvaluacion && 'evaluacion' in faseEvaluacion) {
			parejas.push(wrapParejaLlaveValor('EVALUACION', wrapValor(faseEvaluacion.evaluacion), NIVEL_ACCESO.PUBLICO, NIVEL_ACCESO.RESTRINGIDO))
		} else {
			throw new Error('No se encontró evaluación en fase de evaluación')
		}

		//DEFINICIÓN DE FORMATOS DE DICTAMINACION
		parejas.push(wrapSeccion('DICTAMINACION'))
		const definicionEvaluacionMapping: IMappingDefinicionHomoclave = getDefinicionMappingFromContextoByFase(context, EDatosFase.EVALUACION)
		//DATOS DE DICTAMINACIÓN
		const datosDictaminacionMapping: IMappingDatos = getDatosMapping(getDatosFromContextoByFase(context, EDatosFase.EVALUACION, EDatosNodo.DATOS, indexEvaluacion))
		const seccionesDictaminacion: IMappingDefinicionSeccion[] = Object.values(definicionEvaluacionMapping.secciones).sort((a, b) => a.homoclaveFormatoOrden - b.homoclaveFormatoOrden)
		seccionesDictaminacion.forEach(seccion => {
			seccion.seccion.formularios?.forEach(formulario => {
				if (typeof formulario === 'object') {
					parejas.push(wrapSeccion(getDefinicionEtiquetaFromFormulario(formulario)))
					formulario.campos?.forEach(campo => {
						const valorNormalizado = getValorNormalizadoFromCampo(campo, datosDictaminacionMapping, 'DATO_CAMPO')
						parejas.push(wrapParejaLlaveValor(getDefinicionEtiquetaFromCampo(campo), valorNormalizado.valorNormalizado, getDefinicionNivelAccesoFromCampo(campo), NIVEL_ACCESO.RESTRINGIDO))
					})
				}
			})
		})

		//DEFINICIÓN DE FORMATOS DE SOLICITUD
		//TODO: No integrado a la firma hasta identificar la forma de obtener las evaluaciones en el contexto de la máquina de estados de homoclave
		//parejas.push(wrapSeccion('OBSERVACIONES'))
		const definicionSolicitudMapping: IMappingDefinicionHomoclave = getDefinicionMappingFromContextoByFase(context, EDatosFase.SOLICITUD)
		//DATOS DE OBSERVACIONES
		const datosObservacionesMapping: IEvaluacion = getEvaluacionMapping(getEvaluacionFromContextoByFase(context, EDatosFase.EVALUACION, EDatosNodo.COMENTARIOS, indexEvaluacion))
		const seccionesObservaciones: IMappingDefinicionSeccion[] = Object.values(definicionSolicitudMapping.secciones).sort((a, b) => a.homoclaveFormatoOrden - b.homoclaveFormatoOrden)
		seccionesObservaciones.forEach(seccion => {
			seccion.seccion.formularios?.forEach(formulario => {
				if (typeof formulario === 'object') {
					parejas.push(wrapSeccion(getDefinicionEtiquetaFromFormulario(formulario)))
					formulario.campos?.forEach(campo => {
						if (campo.guid in datosObservacionesMapping) {
							//const valorNormalizado = getValorNormalizadoFromCampo(campo, datosObservacionesMapping, 'COMENTARIO')
							//parejas.push(wrapParejaLlaveValor(getDefinicionEtiquetaFromCampo(campo), valorNormalizado.valorNormalizado, getDefinicionNivelAccesoFromCampo(campo), NIVEL_ACCESO.RESTRINGIDO))
						} else {
							//parejas.push(wrapParejaLlaveValor(getDefinicionEtiquetaFromCampo(campo), wrapValor(''), getDefinicionNivelAccesoFromCampo(campo), NIVEL_ACCESO.RESTRINGIDO))
						}
					})
				}
			})
		})

		//DECLARACIONES
		parejas.push(wrapSeccion('DECLARACIONES'))
		parejas.push(wrapParejaLlaveValor('DECLARACIÓN DE EVALUACIÓN', wrapValor(EFirmaDisclaimer.EVALUACION), NIVEL_ACCESO.PUBLICO, NIVEL_ACCESO.RESTRINGIDO))

		response = wrapCadenaOriginal(wrapParejasLlaveValor(parejas))
	} catch (e) {
		console.error('Error al generar cadena original de evaluación', e)
	}
	return response
}

/**
 * @description Genera cadena original de respuesta prevención
 */
export const FirmaGeneraCadenaOriginalRespuestaPrevencion = function (context: IHomoclaveContext, indexRespuestaPrevencion?: number): string {
	let response: string = ''
	try {
		//RESPUESTA_PREVENCION
		const parejas: string[] = []
		if (indexRespuestaPrevencion == undefined) {
			indexRespuestaPrevencion = getIndiceUltimaEvaluacionFromContexto(context) ?? 0
		}
		parejas.push(wrapSeccion('RESPUESTA A PREVENCIÓN'))
		parejas.push(wrapParejaLlaveValor('DOCUMENTO', wrapValor(EFirmaTipoDocumento.RESPUESTA_PREVENCION), NIVEL_ACCESO.PUBLICO, NIVEL_ACCESO.CONFIDENCIAL))
		parejas.push(wrapParejaLlaveValor('HOMOCLAVE', wrapValor(context.homoclave?.homoclave as string)), NIVEL_ACCESO.PUBLICO, NIVEL_ACCESO.CONFIDENCIAL)
		parejas.push(wrapParejaLlaveValor('MODALIDAD', wrapValor(context.modalidad?.modalidad as string)), NIVEL_ACCESO.PUBLICO, NIVEL_ACCESO.CONFIDENCIAL)
		parejas.push(wrapParejaLlaveValor('RFC SOLICITANTE', wrapValor(context.metadatos?.grupo as string)), NIVEL_ACCESO.PUBLICO, NIVEL_ACCESO.CONFIDENCIAL)
		parejas.push(wrapParejaLlaveValor('SOLICITUD', wrapValor(context.metadatos?.idSolicitud as string)), NIVEL_ACCESO.PUBLICO, NIVEL_ACCESO.CONFIDENCIAL)
		parejas.push(wrapParejaLlaveValor('TRAMITE', wrapValor(context.metadatos?.idTramite as string)), NIVEL_ACCESO.PUBLICO, NIVEL_ACCESO.CONFIDENCIAL)
		parejas.push(wrapParejaLlaveValor('NÚMERO DE RESPUESTA A PREVENCIÓN', wrapValor((indexRespuestaPrevencion + 1).toString())), NIVEL_ACCESO.PUBLICO, NIVEL_ACCESO.CONFIDENCIAL)

		//Datos
		const definicionMapping: IMappingDefinicionHomoclave = getDefinicionMappingFromContextoByFase(context, EDatosFase.SOLICITUD)
		const datosMapping: IMappingDatos = getDatosMapping(getDatosFromContextoByFase(context, EDatosFase.RESPUESTA_PREVENCION, EDatosNodo.DATOS, indexRespuestaPrevencion))
		const secciones: IMappingDefinicionSeccion[] = Object.values(definicionMapping.secciones).sort((a, b) => a.homoclaveFormatoOrden - b.homoclaveFormatoOrden)
		secciones.forEach(seccion => {
			seccion.seccion.formularios?.forEach(formulario => {
				if (typeof formulario === 'object') {
					parejas.push(wrapSeccion(getDefinicionEtiquetaFromFormulario(formulario)))
					formulario.campos?.forEach(campo => {
						const valorNormalizado = getValorNormalizadoFromCampo(campo, datosMapping, 'DATO_CAMPO')
						parejas.push(wrapParejaLlaveValor(getDefinicionEtiquetaFromCampo(campo), valorNormalizado.valorNormalizado, getDefinicionNivelAccesoFromCampo(campo), NIVEL_ACCESO.CONFIDENCIAL))
					})
				}
			})
		})

		//DECLARACIONES
		parejas.push(wrapSeccion('DECLARACIONES'))
		parejas.push(wrapParejaLlaveValor('DECLARACIÓN DE RESPUESTA A PREVENCIÓN', wrapValor(EFirmaDisclaimer.RESPUESTA_PREVENCION), NIVEL_ACCESO.PUBLICO, NIVEL_ACCESO.CONFIDENCIAL))
		response = wrapCadenaOriginal(wrapParejasLlaveValor(parejas))
	} catch (e) {
		console.error('Error al generar cadena original de evaluación', e)
	}
	return response
}

/**
 * @description Genera cadena original de resollucion
 */
export const FirmaGeneraCadenaOriginalResolucion = function (context: IHomoclaveContext, indexEvaluacion?: number): string {
	let response: string = ''
	try {
		const parejas: string[] = []
		if (indexEvaluacion == undefined) {
			indexEvaluacion = getIndiceUltimaEvaluacionFromContexto(context) ?? 0
		}
		parejas.push(wrapSeccion('RESOLUCIÓN'))
		parejas.push(wrapParejaLlaveValor('TIPO DE DOCUMENTO', wrapValor(EFirmaTipoDocumento.RESOLUCION), NIVEL_ACCESO.PUBLICO, NIVEL_ACCESO.RESTRINGIDO))
		parejas.push(wrapParejaLlaveValor('HOMOCLAVE', wrapValor(context.homoclave?.homoclave as string), NIVEL_ACCESO.PUBLICO, NIVEL_ACCESO.RESTRINGIDO))
		parejas.push(wrapParejaLlaveValor('MODALIDAD', wrapValor(context.modalidad?.modalidad as string), NIVEL_ACCESO.PUBLICO, NIVEL_ACCESO.RESTRINGIDO))
		parejas.push(wrapParejaLlaveValor('RFC SOLICITANTE', wrapValor(context.metadatos?.grupo as string), NIVEL_ACCESO.PUBLICO, NIVEL_ACCESO.RESTRINGIDO))
		parejas.push(wrapParejaLlaveValor('SOLICITUD', wrapValor(context.metadatos?.idSolicitud as string), NIVEL_ACCESO.PUBLICO, NIVEL_ACCESO.RESTRINGIDO))
		parejas.push(wrapParejaLlaveValor('TRAMITE', wrapValor(context.metadatos?.idTramite as string), NIVEL_ACCESO.PUBLICO, NIVEL_ACCESO.RESTRINGIDO))
		parejas.push(wrapParejaLlaveValor('NÚMERO DE RESOLUCIÓN', wrapValor((indexEvaluacion + 1).toString()), NIVEL_ACCESO.PUBLICO, NIVEL_ACCESO.RESTRINGIDO))

		const faseEvaluacion = getFaseEvaluacionFromContexto(context, indexEvaluacion)
		if (faseEvaluacion && 'evaluacion' in faseEvaluacion) {
			parejas.push(wrapParejaLlaveValor('RESOLUCIÓN', wrapValor(faseEvaluacion.evaluacion), NIVEL_ACCESO.PUBLICO, NIVEL_ACCESO.RESTRINGIDO))
		} else {
			throw new Error('No se encontró evaluacion en fase de evaluación')
		}

		//DEFINICIÓN DE FORMATOS DE DICTAMINACION
		parejas.push(wrapSeccion('DICTAMINACION'))
		const definicionEvaluacionMapping: IMappingDefinicionHomoclave = getDefinicionMappingFromContextoByFase(context, EDatosFase.EVALUACION)
		//DATOS DE DICTAMINACIÓN
		const datosDictaminacionMapping: IMappingDatos = getDatosMapping(getDatosFromContextoByFase(context, EDatosFase.EVALUACION, EDatosNodo.DATOS, indexEvaluacion))
		const seccionesDictaminacion: IMappingDefinicionSeccion[] = Object.values(definicionEvaluacionMapping.secciones).sort((a, b) => a.homoclaveFormatoOrden - b.homoclaveFormatoOrden)
		seccionesDictaminacion.forEach(seccion => {
			seccion.seccion.formularios?.forEach(formulario => {
				if (typeof formulario === 'object') {
					parejas.push(wrapSeccion(getDefinicionEtiquetaFromFormulario(formulario)))
					formulario.campos?.forEach(campo => {
						const valorNormalizado = getValorNormalizadoFromCampo(campo, datosDictaminacionMapping, 'DATO_CAMPO')
						parejas.push(wrapParejaLlaveValor(getDefinicionEtiquetaFromCampo(campo), valorNormalizado.valorNormalizado, getDefinicionNivelAccesoFromCampo(campo), NIVEL_ACCESO.RESTRINGIDO))
					})
				}
			})
		})

		//DECLARACIONES
		parejas.push(wrapSeccion('DECLARACIONES'))
		parejas.push(wrapParejaLlaveValor('DECLARACIÓN', wrapValor(EFirmaDisclaimer.RESOLUCION), NIVEL_ACCESO.PUBLICO, NIVEL_ACCESO.RESTRINGIDO))

		response = wrapCadenaOriginal(wrapParejasLlaveValor(parejas))
	} catch (e) {
		console.error('Error al generar cadena original de resolucion', e)
	}
	return response
}
/**
 * @name FirmaCadenaOriginalCofepagos
 * @description Genera la cadena original para la firma de la solicitud de pago de Cofepagos
 */
export const FirmaCadenaOriginalCofepagos = function (transaccionTipo: ECofepagosTransaccionTipo, idSolicitante: string, llavesPago: ICofepagosLlavePagoBase[]): string {
	let response: string = ''
	try {
		const parejas: string[] = []
		parejas.push(wrapParejaLlaveValor('TIPO TRANSACCION', wrapValor(transaccionTipo), NIVEL_ACCESO.INTERNO, NIVEL_ACCESO.INTERNO))
		parejas.push(wrapParejaLlaveValor('ID SOLICITANTE', wrapValor(idSolicitante), NIVEL_ACCESO.INTERNO, NIVEL_ACCESO.INTERNO))
		parejas.push(wrapParejaLlaveValor('CANTIDAD LLAVES', wrapValor(llavesPago.length.toString()), NIVEL_ACCESO.INTERNO, NIVEL_ACCESO.INTERNO))
		let contador = 1
		llavesPago.forEach(llavePago => {
			parejas.push(wrapSeccion(`LLAVE DE PAGO ${contador}`))
			parejas.push(wrapParejaLlaveValor('LLAVE DE PAGO', wrapValor(llavePago.llavePago), NIVEL_ACCESO.INTERNO, NIVEL_ACCESO.INTERNO))
			parejas.push(wrapParejaLlaveValor('IMPORTE', wrapValor(llavePago.importe.toString()), NIVEL_ACCESO.INTERNO, NIVEL_ACCESO.INTERNO))
			contador++
		})
		response = wrapCadenaOriginal(wrapParejasLlaveValor(parejas))
	} catch (error) {
		console.error(`Error en generarCadenaFirma}`)
		response = ''
	}
	return response
}
export const FirmaCadenaOriginalActivoSalud = function (idSolicitante: string, activo: IAIInsumoSalud): string {
	let response: string = ''
	try {
		const parejas: string[] = []
		parejas.push(wrapParejaLlaveValor('idSolicitante', wrapValor(idSolicitante), NIVEL_ACCESO.INTERNO, NIVEL_ACCESO.INTERNO))
		parejas.push(wrapParejaLlaveValor('ACTIVO', wrapValor(stringify(activo)), NIVEL_ACCESO.INTERNO, NIVEL_ACCESO.INTERNO))
		response = wrapCadenaOriginal(wrapParejasLlaveValor(parejas))
	} catch (error) {
		console.error(`Error en generarCadenaFirma}`)
		response = ''
	}
	return response
}

/**
 * @description Reemplazar los caracteres no imprimibles y los caracteres delimitadores de la cadena original por cadena vacía
 */
export const filtrarCaracteresNoValidosEnCadenaOriginal = function (valor: string): string {
	let response: string = valor || ''
	try {
		// eslint-disable-next-line no-control-regex
		const noImprimiblesRegex = /[\x00-\x1F\x7F-\x9F]+/gi
		response = response.replaceAll(noImprimiblesRegex, '')

		Object.keys(EFirmaDelimitador).forEach(key => {
			const caracter = EFirmaDelimitador[key as keyof typeof EFirmaDelimitador] as string
			response = response.replaceAll(caracter, CFirmaDelimitadorRemplazo[key as keyof typeof EFirmaDelimitador])
		})
	} catch (e) {
		console.error('Error al filtrar caracteres no válidos en cadena original', valor, e)
	}

	return response.trim()
}

/**
 * @description Genera cadena original Agregando delimitadores de inicio y fin de cadena
 */
export const wrapCadenaOriginal = function (parejasCampos: string): string {
	let response: string = ''
	try {
		response += EFirmaDelimitador.DELIMITADOR_INICIO_FIN_CADENA
		response += parejasCampos
		response += EFirmaDelimitador.DELIMITADOR_INICIO_FIN_CADENA
	} catch (e) {
		console.error('Error al generar el wrap de cadena original', parejasCampos, e)
	}
	return response
}

/**
 * @description Genera cadena de delimitador de sección en la cadena original
 */
export const wrapSeccion = function (seccion: string): string {
	let response: string = ''
	try {
		response += EFirmaDelimitador.DELIMITADOR_SECCION
		response += wrapValor(seccion)
		response += EFirmaDelimitador.DELIMITADOR_SECCION
	} catch (e) {
		console.error('Error al generar el wrap de seccion', seccion, e)
	}
	return response
}

/**
 * @description Genera cadena de parejas llave valor Agregando delimitador de parejas
 */
export const wrapParejasLlaveValor = function (parejasLlaveValor: string[]): string {
	let response: string = ''
	try {
		if (parejasLlaveValor.length == 0) {
			response = ''
		} else if (parejasLlaveValor.length == 1) {
			response = parejasLlaveValor[0]
		} else {
			for (let i = 0; i < parejasLlaveValor.length; i++) {
				if (i == parejasLlaveValor.length - 1) {
					response += parejasLlaveValor[i]
				} else {
					response += parejasLlaveValor[i] + EFirmaDelimitador.DELIMITADOR_PAREJAS
				}
			}
		}
	} catch (e) {
		console.error('Error al generar wrap de parejas llave valor', parejasLlaveValor, e)
	}
	return response
}

/**
 * @description Genera cadena de pareja llave valor Agregando delimitador de llave valor
 */
export const wrapParejaLlaveValor = function (llave: string, valor: string, nivelAcceso?: NIVEL_ACCESO, contexto?: NIVEL_ACCESO): string {
	let response: string = ''
	if (nivelAcceso == undefined) nivelAcceso = NIVEL_ACCESO.NO_CLASIFICADO
	if (contexto == undefined) contexto = NIVEL_ACCESO.NO_CLASIFICADO
	try {
		//console.log(llave + ':' + valor + ':' + nivelAcceso + ':' + contexto)
		response =
			wrapValor(llave) + EFirmaDelimitador.DELIMITADOR_LLAVE_VALOR + nivelAcceso + EFirmaDelimitador.DELIMITADOR_LLAVE_VALOR + ConfidencialidadTestarByNivelAccesoContexto(valor, nivelAcceso, contexto)
	} catch (e) {
		console.error('Error al generar wrap de pareja llave valor', llave, valor, e)
	}
	return response
}

/**
 * @description Genera cadena de tabla Agregando delimitadores de inicio y fin de tabla
 */
export const wrapTabla = function (filas: string[]): string {
	let response: string = ''
	try {
		response = EFirmaDelimitador.DELIMITADOR_VALOR_INICIO_TABLA
		filas.forEach(fila => {
			response += fila
		})
		response += EFirmaDelimitador.DELIMITADOR_VALOR_FIN_TABLA
	} catch (e) {
		console.error('Error al generar wrap de tabla', filas, e)
	}
	return response
}

/**
 * @description Genera cadena de fila Agregando delimitadores de inicio y fin de fila
 */
export const wrapFila = function (celdas: string[]): string {
	let response: string = ''
	try {
		response = EFirmaDelimitador.DELIMITADOR_VALOR_INICIO_FILA
		celdas.forEach(celda => {
			response += celda
		})
		response += EFirmaDelimitador.DELIMITADOR_VALOR_FIN_FILA
	} catch (e) {
		console.error('Error al generar wrap de fila', celdas, e)
	}
	return response
}

/**
 * @description Genera cadena de celda Agregando delimitadores de inicio y fin de celda
 */
export const wrapCelda = function (valor?: string, type?: CUSTOM_TYPE): string {
	let response: string = ''
	try {
		switch (type) {
			case CUSTOM_TYPE.FILE:
				response = EFirmaDelimitador.DELIMITADOR_VALOR_INICIO_CELDA + (valor ?? '') + EFirmaDelimitador.DELIMITADOR_VALOR_FIN_CELDA
				break
			case CUSTOM_TYPE.TABLE:
				response = EFirmaDelimitador.DELIMITADOR_VALOR_INICIO_CELDA + (valor ?? '') + EFirmaDelimitador.DELIMITADOR_VALOR_FIN_CELDA
				break
			case CUSTOM_TYPE.ARRAY:
				response = EFirmaDelimitador.DELIMITADOR_VALOR_INICIO_CELDA + (valor ?? '') + EFirmaDelimitador.DELIMITADOR_VALOR_FIN_CELDA
				break
			default:
				response = EFirmaDelimitador.DELIMITADOR_VALOR_INICIO_CELDA + wrapValor(valor) + EFirmaDelimitador.DELIMITADOR_VALOR_FIN_CELDA
		}
	} catch (e) {
		console.error('Error al generar wrap de celda', valor, e)
	}

	return response
}

/**
 * @description Genera cadena de cabecera Agregando delimitadores de inicio y fin de abecera
 */
export const wrapCabecera = function (valor: string): string {
	let response: string = ''
	try {
		response = EFirmaDelimitador.DELIMITADOR_VALOR_INICIO_CABECERA + wrapValor(valor) + EFirmaDelimitador.DELIMITADOR_VALOR_FIN_CABECERA
	} catch (e) {
		console.error('Error al generar wrap de celda', valor, e)
	}

	return response
}

/**
 * @description Filtra los caracteres no válidos en los valores de las parejas en cadena original y utiliza el valor por default en caso de que el valor sea vacío
 */
export const wrapValor = function (valor?: string): string {
	if (valor === EFirmaDelimitador.DELIMITADOR_VALOR_VACIO) {
		return valor
	}
	let response: string = ''
	try {
		if ((valor?.trim() ?? '') == '') {
			response = EFirmaDelimitador.DELIMITADOR_VALOR_VACIO
			return response
		}
		response = filtrarCaracteresNoValidosEnCadenaOriginal(valor!)
		if (response.length === 0) {
			response = EFirmaDelimitador.DELIMITADOR_VALOR_VACIO
		}
	} catch (e) {
		console.error('Error al generar wrap de valor', valor, e)
	}
	return response
}

/**
 * @description Obtiene un booleano que indica si el valor es vacío
 */
export const isVacio = function (valor: string): boolean {
	let response = false
	try {
		response = valor === (EFirmaDelimitador.DELIMITADOR_VALOR_VACIO as string)
	} catch (e) {
		console.error('Error al validar si el valor es vacío', valor, e)
	}
	return response
}

/*
 * @description Obtiene el valor normalizado de un campo, verificando que exista en el mapping o dando un valor Normalizado Vacío
 */
export const getValorNormalizadoFromCampo = function (campo: ICampoFormulario, datosMapping: IMappingDatos | IEvaluacion, tipo: 'COMENTARIO' | 'DATO_CAMPO'): IDatoValorNormalizado {
	let response: IDatoValorNormalizado = {
		valorNormalizado: wrapValor('')
	}
	try {
		const datoMapeo: IDato | IEvaluacionItem = datosMapping[campo.idDato ?? campo.guid]
		if (datoMapeo != undefined) {
			//datosObservacionesMapping[campo.guid]
			switch (tipo) {
				case 'COMENTARIO':
					response = getEvaluacionValorNormalizadoFromComentario(datoMapeo as IEvaluacionItem)
					break
				case 'DATO_CAMPO':
					response = getDatosValorNormalizadoFromCampo((datoMapeo as IDato).campo)
					break
			}
		}
	} catch (e) {
		console.error('Error al obtener valor normalizado de campo', e)
	}
	return response
}
